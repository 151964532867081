
import Icon from '@/components/Icon.vue';

interface ILink { title: string; link: string; }

const LINKS: ILink[] = [
  {
    title: '$GOOD Community Pre-Seed (EN)',
    link: 'https://t.me/+7K5P0JBp79VkMDJh',
  },
  {
    title: '$GOOD Комьюнити Пресейл (RU)',
    link: 'https://t.me/+sinOuGEK-3FkM2Zh',
  },
];

export default {
  name: 'Support',
  components: { Icon },
  computed: {
    LINKS (): ILink[] {
      return LINKS;
    },
  },
};
